import full from '@/public/images/heart_lung_full.png'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Alert, Box, Button, Paper, Stack, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Image from 'next/image'
import logo from 'public/logo512.png'
import React from 'react'

export interface AuthProps {
  email: string
  password: string
  confirmPassword: string
  showPassword: boolean
}

export interface IAlertProps {
  type: AlertType
  message: string
}

export interface IInputError {
  message: string
}

export enum AlertType {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}

export interface AuthLayoutProps {
  children: any
  busy: boolean
  alert: IAlertProps | null
  handleSubmit: any
  title: string
  description: string
  buttonName: string
}

export default function AuthLayout({
  children,
  busy,
  alert,
  handleSubmit,
  title = 'Hi, Welcome!',
  description = 'Please Sign in to continue.',
  buttonName = 'Sign In',
}: AuthLayoutProps) {
  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100vh',
        backgroundColor: 'Background.default',
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={6}
        md={7}
        sx={
          {
            // backgroundImage: 'url(https://source.unsplash.com/random)',
            // backgroundRepeat: 'no-repeat',
            // backgroundColor: (t) =>
            //   t.palette.mode === 'light'
            //     ? t.palette.grey[50]
            //     : t.palette.grey[900],
            // backgroundSize: 'cover',
            // backgroundPosition: 'center',
          }
        }
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {/*<Image sx={} />*/}
        {/*<NoImageIcon sx={{ fontSize: 90 }} />*/}

        <Image width={150} src={full} alt={'full'} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={0}
        square
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            width: '100%',
            maxHeight: 537,
            maxWidth: 480,
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction="column" justifyContent="center" alignItems="flex-start">
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Image src={logo} alt={'logo'} height={33} width={33} />
              <Typography
                sx={{
                  mx: 1.5,
                  fontSize: 19,
                  fontWeight: 400,
                  fontFamily: 'Work Sans',
                  color: 'neutral.900',
                }}
              >
                HeartLung
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                mt: 2.5,
              }}
            >
              <Typography
                sx={{
                  fontSize: 33,
                  fontWeight: 700,
                  fontFamily: 'Work Sans',
                  color: 'neutral.900',
                }}
              >
                {title}
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 400, color: 'neutral.500' }}>{description}</Typography>
            </Grid>
          </Grid>
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <Grid sx={{ my: 5, width: '100%' }}>{children}</Grid>
            <Button type="submit" fullWidth variant="contained" disabled={busy} sx={{ height: 44 }}>
              {buttonName}
            </Button>
            {alert && (
              <Stack sx={{ width: '100%', marginY: 5 }} spacing={2}>
                <Alert
                  severity={alert.type}
                  iconMapping={{
                    success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    error: <ErrorOutlineIcon fontSize="inherit" />,
                    warning: <WarningAmberIcon fontSize="inherit" />,
                    info: <InfoIcon fontSize="inherit" />,
                  }}
                >
                  {alert.message}
                </Alert>
              </Stack>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
