import Head from 'next/head'
import Grid from '@mui/material/Grid'

export interface GuestLayoutProps {
  children: any
  title: string
}

const GuestLayout = ({ children, title = 'livelong panel' }: GuestLayoutProps) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <Grid>{children}</Grid>
    </>
  )
}

export default GuestLayout
